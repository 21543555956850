* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-content {
  /* background-color: red; */
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 123;
}

.navbar-nav li {
  margin-right: 20px;
  font-weight: 600;
}

.btn-primary-v2 {
  background-color: #359dab;
  color: #fff;
  padding: 10px 30px;
  border-radius: 25px;
  font-weight: 600;
}

.btn-primary-v2:hover {
  background-color: #2d8490;
  color: #fff;
}

.banner-content {
  background-color: #F4F1EA;
  min-height: 600px;
}

.info-content {
  margin: 75px 0;
}

.info-card-title {
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.info-card-paragraph {
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.info-card-content {
  margin-top: 100px;
}

.single-info-content {
  margin: 175px 0;
}

.single-info-title {
  font-size: 64px;
  font-weight: 400;
  letter-spacing: 1.5px;
  width: 60%;
  margin: auto;
  text-align: center;
}

.contact-content{
  margin: 75px 0;
}

.contact-content-container {
  position: relative;
  background-color: #359dab;
  /* min-height: 700px; */
  border-radius: 40px;
}

.contact-content-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://sqy7rm.media.zestyio.com/Tree-Asset.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.1;
  z-index: 0;
}

.contact-content-container * {
  position: relative;
  z-index: 1;
}

.form-label{
  font-weight: 600;
  color: #fff;
}

.form-control{
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff!important;
  border-radius: 25px;
  padding: 10px 15px;
}

.form-control:focus{
  background-color: transparent;
  outline: none;
  border: 1px solid #fff;
  box-shadow: none;
}

textarea{
  min-height: 150px!important;
}

.form-control::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

.contact-title{
  color: #fff;
  font-size: 46px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.contact-paragraph{
  color: #fff;
  font-size: 18px;
  letter-spacing: .5px;
}

.footer-content{
  border-top: 1px solid #efefef;
}

.footer-content p{
  font-size: 15px;
  color: #8f8f8f;
}

.about-us-content{
  background-color: #F4F1EA;
  margin-bottom: 140px;
}

.btn-light{
  border-radius: 20px;
}

@media (max-width: 992px){
  .single-info-title{
    width: 100%;
  }
  .info-image{
    width: 100%;
    padding: 25px 0;
  }

  .info-content .text-end{
    text-align: start!important;
  }

  .about-us-content img{
    width: 100%;
    padding: 30px 0 0 0;
  }

  .contact-mobile{
    flex-direction: column-reverse;
  }
}